.carrer_wrapper {
  padding: 30px 0;
}
.carrer_container {
  width: 90%;
  margin: 0 auto;
}
.carrer_item {
  display: grid;
  grid-template-columns: 48% 44%;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}

.carrer_container h1 {
  text-align: center;
  padding: 10px 0;
}
.carrer_item p {
  line-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form_wrapper {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.form {
  padding: 20px;
}
.form div {
  margin-bottom: 15px;
}

.form input[type="text"],
.form input[type="email"],
.form input[type="tel"],
.form textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  outline: none;
}

.form input[type="submit"] {
  background-color: #7e52a0;
  color: white;
  padding: 10px 30px;
  border: none;
  border-radius: 3px;
  font-size: 18px;
  cursor: pointer;
}
.form_button {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Mobile optimization */
@media (max-width: 768px) {
  .carrer_item {
    grid-template-columns: 100%;
  }
}
