.welc-cont h2 {
  color: #332a86 !important;
}

.topbar-btn-color {
  background-color: #7e52a0 !important;
  color: white !important;
}
.topbar-btn-color-contact {
  background-color: #7e52a0 !important;
}
.topbar-btn-color-contact:hover {
  background-color: #7e52a0 !important;
  color: #fff !important;
}
/* p.date {
  color: #332a86 !important;
} */

img.img-banner-size {
  /* border: 2px solid red; */
  width: 100%;
  height: 400px;
}
.blue-background-width {
  width: 41%;
  padding: 30px;
}
.crs_content {
  text-align: left;
  background-color: rgba(0, 8, 255, 0.32);
  padding: 15px 0px;
}
@media screen and (max-width: 600px) {
  .crs_content h2 {
    font-size: 24px !important;
  }
  .crs_content p {
    font-size: 18px !important;
  }

  #carouselExampleCaptions img {
    min-height: 300px;
  }
  #kindlyLeft {
    text-align: justify;
  }
}
@media screen and (max-width: 300px) {
  .crs_content {
    position: absolute;
    top: 0%;
  }

  .crs_content h2 {
    font-size: 15px !important;
  }
  .crs_content p {
    display: none !important;
  }
}
@media only screen and (min-width: 1050px) {
  .crs_content {
    position: absolute;
    bottom: 10rem;
  }
}

/* cards container */
.cards-container {
  display: flex;
  margin: 10px;
  padding: 10px;
}
.cards-container div {
  height: 355px;
}
@media (max-width: 576px) {
  .cards-container {
    display: flex;
    flex-direction: column;
  }
}
.cards-style h5 {
  color: #332a86 !important;
  text-align: center;
  padding: 8px 0px;
  font-size: 18px;
}
.cards-style img {
  height: 100px;
  width: 90px;
  margin-left: 40%;
}
.cards {
  text-align: center;
}
.card-btn {
  /* border: 2px solid red !important; */
  padding: 10px;
  margin: 23px 12px !important;
  background-color: #332a86 !important;
  color: white;
}

.service-section {
  margin: 10px;
  padding: 5px 121px !important;
}

.button {
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 6px 9px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
}
.img-size {
  width: 100%;
  height: auto;
  border-radius: 13px;
}
.img-size {
  border: 2px solid #332a86 !important;
}
/* progrss bar */

.progress {
  width: 300px;
  height: 300px !important;
  line-height: 40px;
  /* margin: 20px; */
  position: relative;
  background-color: #fff !important;
}

.progress > span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}

.progress .progress-bar {
  width: 100%;
  height: 100%;
  background: none;
  border-width: 4px;
  border-style: solid;
  position: absolute;
}

.progress .progress-left .progress-bar {
  left: 100%;
  border-top-right-radius: 150px;
  border-bottom-right-radius: 150px;
  border-left: 0;
  -webkit-transform-origin: center left;
  transform-origin: center left;
}

.progress .progress-right {
  right: 0;
}

.progress .progress-right .progress-bar {
  left: -100%;
  border-top-left-radius: 150px;
  border-bottom-left-radius: 150px;
  border-right: 0;
  -webkit-transform-origin: center right;
  transform-origin: center right;
  animation: loading-1 1.8s linear forwards;
}

.progress .progress-value {
  margin: 80px;
}
.progress-top-text {
  font-size: 1.6em;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
}
.progress-number {
  font-size: 2.6rem;
  font-weight: 700;
}
.progress-below-text {
  font-size: 1rem;
  line-height: 22px;
}
.progress.blue .progress-bar {
  border-color: #332a86;
}

.progress.blue .progress-left .progress-bar {
  animation: loading-2 1.5s linear forwards 1.8s;
}

@keyframes loading-1 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

@keyframes loading-2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}
@media (max-width: 576px) {
  .service-section {
    margin: 10px;
    padding: 5px 5px !important;
    text-align: center !important;
  }
}
/* welcome container */
.welcome-container {
  /* display: flex; */
  padding: 10px 110px;
  margin: 20px;
}
.welcome-container1 {
  padding: 30px 5px;
}
@media (max-width: 576px) {
  .welcome-container {
    display: flex;
    flex-direction: column;
    margin: 10px;
    padding: 10px 20px;
  }
}
.welcome-container > div {
  flex-basis: 50%;
  margin: 10px;
}

.button {
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 6px 9px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
}
.button:hover {
  background-color: #332a86 !important;
}
/* .About Our Center */
.about-section {
  background: #ececed;
}
@media (max-width: 576px) {
  .about-container {
    display: flex;
    flex-direction: column;
    background: #ececed;
  }
}
.about-container {
  display: flex;
  background: #ececed;
  padding: 2% 10%;
  flex-wrap: nowrap;
}
.about-container > div {
  flex-basis: 33%;
  padding: 10px;
}

/* //Accodian */
@media only screen and (max-width: 480px) {
  .acdn h1 {
    font-size: 28px !important;
  }
  .acdn h5 {
    font-size: 18px !important;
  }
  .acdn h6 {
    font-size: 16px !important;
  }
  .acdn p {
    font-size: 15px;
  }
}
.home_accordian svg {
  color: rgb(126, 82, 160) !important;
}
.acdn h3 {
  line-height: 2;
}
.acdn {
  text-align: left !important;
}

.acdn button {
  box-shadow: none !important;
  float: left !important;
}
.acdnIcon {
  color: #332a86 !important;
  font-size: 38px !important;
  position: relative !important;
  top: -10px !important;
}

/*Testomianls*/
.testimonials-section {
  margin: 0px 0px 40px;
  padding: 30px 0px;
  text-align: center;
}
.testimonials-section-top-text h2 {
  padding: 20px !important;
  margin-top: 0px !important;
  font-size: 32px;
  color: #9a5ba4;
}
.testimonials-section .owl-stage {
  display: flex;
}

/* .testimonials-section .owl-item {
  height: 100% !important;
} */

.testimonial_items_wrapper {
  padding: 0px 30px !important;

  position: relative;
}
.testimonial_custom_nav {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  justify-content: space-between;
}

.testimonial_custom_nav button {
  padding: 5px;
  border: none;
  background: none;
  color: #9a5ba4;
  font-size: 30px;
}

.testimonials-item {
  color: #9a5ba4;
  border: 1px solid #9a5ba4;
  font-weight: 400;
  height: 100%;

  background-color: #fff;
  padding: 15px;

  width: 95%;
  margin: 0 auto;

  border-radius: 15px;
}

p.testimonials-item-name {
  margin-bottom: 0px !important;

  font-size: 25px;
  font-weight: bold;
}
.testimonial_star img {
  width: 50% !important;
  margin: 0 0 0 auto;
}
.testimonials-item p {
  text-align: left;
}

.image_container {
  display: grid;
  grid-template-columns: 30% 40%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.testimonial_google img {
  width: 40px !important;
}
#testimonial_google_home .testimonial_star img {
  width: 40% !important;
}
#testimonial_google_home .testimonials-item {
  border: none;
}
@media (min-width: 992px) {
  .blog-container {
    margin: 100px !important;
    position: relative;
  }
}
@media (max-width: 650px) {
  #testimonial_google_home .testimonial_star img {
    width: 100% !important;
  }
}
@media (max-width: 576px) {
  /* .blog-container { 
   margin: 10px !important;
    position: relative;
    min-width: 200px;
    min-height: 197px; 
     display: none; 
  } */
  .blog-slider-section-mobile {
    display: flex;
  }
  /* .blog-section-top-text h5 {
    display: none;
  } */
  .blog-container img {
    height: 200px;
  }
  .blog-container {
    padding: 15px;
  }
  .blog-container p {
    padding-left: 15px;
  }
}

.blog-section-top-text h5 {
  padding: 10px;
  font-size: 32px;
  text-align: center;
  color: #332a86;
}
.bottom-left {
  position: absolute;
  bottom: 10px;
  left: 16px;
  width: 70%;
  color: white;
}
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.bottom-left p.date {
  font-weight: bold;
}
.bottom-left p.title {
  font-weight: bold;
}
.bottom-left p.link {
  font-weight: bold;
}
.bottom-left p.link::before {
  content: "+ ";
}
.blog-container img {
  filter: brightness(50%);
}
.blog-container img:hover {
  filter: brightness(90%);
}
.btn-color-services {
  background-color: #332a86 !important;
  color: white !important;
}
#homeHavingCard {
  /* border: 2px solid red; */
  /* width: 100%;
  height: 100%; */
}
#homeHavingCard .card {
  /* border: 2px solid #332a86; */
  position: relative;
  width: 100%;
  height: 270px;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  /* padding: 50px 0; */
}
#homeHavingCard .card:hover {
  transform: rotateY(180deg);
}
#homeHavingCard .card-header,
#homeHavingCard .card-body {
  position: absolute;
  top: 0%;
  bottom: 0%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  /* padding: 30px; */
}
#homeHavingCard.card-header {
  /* border: 2px solid green;
  padding: 30px ; */
  /* background-color: #d08f8f;
  color: black; */
}
#homeHavingCard .card-body {
  background-color: #7e52a0;
  color: white;
  transform: rotateY(180deg);
  padding: 40px;
}
#homeHavingCard .text-center a {
  background-color: white;
  color: #7e52a0;
}
#homeHavingCard .card h5 {
  color: #332a86 !important;
  font-size: 25px !important;
}
#homeHavingCard .card {
  background-color: #e1e1e2 !important;
  box-shadow: -1px 3px 7px 1px rgba(150, 144, 144, 0.75);
}
#homeHavingCard a {
  border: none;
}
/* #homeHavingCard .card:hover {
  position: relative;
  bottom: 2px;
  box-shadow: none;
} */
#welcome-firtility-text {
  font-size: 18px;
  text-align: justify;
  line-height: 1.5;
}
.seo-text {
  padding: 0% 6%;
  text-align: center;
}

.banner_icon {
  border: 2px solid red;
  background-color: red;
}

.banner_wrapper {
  position: relative;
}
.banner_wrapper .owl-dots {
  display: none;
}
.banner_custom_nav {
  position: absolute;
  /* bottom: 0; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 95%;
  z-index: 999;

  display: flex;
  justify-content: space-between;
}

.banner_custom_nav button {
  padding: 5px;
  border: none;
  background: none;
  color: #7e52a0;
  font-size: 30px;
}

#download_app_text {
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 30px;
}
#download_app_text a {
  font-size: 25px !important;
  margin-bottom: 0px !important;
  display: flex;
  justify-content: center;
}
#download_app_text h3 {
  margin-bottom: 15px;
  font-size: 25px !important;
  font-weight: 600;
}
#download_app_text img {
  width: 100%;
  max-width: 220px;
  margin: 0 auto;
}
.download_app {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.download_app_text img {
  display: block;
  margin: 0 auto;
}

@media (max-width: 650px) {
  #download_app_text {
    flex-direction: column;
  }
}
