.banner-header-facility {
  background-image: linear-gradient(
      45deg,
      rgba(161, 161, 161, 0.596),
      rgba(44, 44, 44, 0.493)
    ),
    url("./components/assets/img/facility/facility/facility-banner.jpg");
  padding: 13% 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left center;
  color: #fff;
}
#fertolity-card img {
  height: 200px;
}
#facility-img-contnent img {
  width: 350px;
  height: 250px;
}
.h1-top-text {
  text-align: center;
  color: #771fbb;
  margin-bottom: 18px;
  font-size: 27px;
}
