.wrapper {
  background-image: url("../assets/img/aboutus/about-page-section-two.jpg");
  background-size: cover;
  padding: 80px 0 200px;
  background-repeat: no-repeat;
  /* background-size: cover; */
  background-position: center;
  position: relative;
  isolation: isolate;
}
.wrapper::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(126, 82, 160, 0.7);
  z-index: -1;
}
.container h1 {
  text-align: center;
  padding: 30px 0;
  color: white;
  font-size: 40px !important;
  font-weight: 500;
}
.button_items {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
}
.button_item {
  width: 30%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #7e52a0;
}
.button_item button {
  background-color: #7e52a0;
  color: white;
  border: none;
}
.button_item a {
  font-size: 19px;
  font-weight: 400;
  letter-spacing: 0.5px;

  color: #fff;
}
.button_item a:hover {
  color: #fff;
}
