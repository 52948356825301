.events-img img{
  width: 100%;
  height: 280px;
}
.events-container{
  position: relative;
}
.events-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color:rgba(70, 44, 82, 0.7) ;
  overflow: hidden;
  width: 0;
  height: 100%;
  transition: .5s ease;
}
.events-text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  white-space: nowrap;
}
.events-img:hover .events-overlay {
  width: 100%;

}