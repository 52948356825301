#contactUs-banner {
  background-image: linear-gradient(15deg, #7e52a0 40%, #ea3288 60%);
}
#contactUs-banner h2 {
  color: #fff;
}
#contactUs-banner .fa-map-marker-alt:hover {
  color: #7e52a0 !important;
  cursor: pointer;
}
#contactUs-banner .container > *,
#contactUs-banner.container-fluid > *,
#contactUs-banner.container-lg > *,
#contactUs-banner.container-md > *,
#contactUs-banner.container-sm > *,
#contactUs-banner.container-xl > *,
#contactUs-banner.container-xxl > * {
  padding-right: 5px !important;
}
#make-me-greeen {
  background-color: rgb(253, 247, 247);
}
.related-links i:hover {
  cursor: pointer;
  background-color: #7e52a0 !important;
}
.related-links span {
  font-weight: bold;
}
.related-links span,
.related-links p {
  color: #ea3288;
}
.related-links .fa-map-marker-alt,
.related-links .fa-phone-alt,
.related-links .fa-envelope {
  background-color: #ef586f !important;
  color: #fff;
  padding: 5px;
  border-radius: 50%;
  margin-right: 20px;
  font-size: 22px;
}
