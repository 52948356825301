#banner-logo {
  background-image: url(../../assets/img/media/blogs/12.jpg);
  padding: 10% 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
#banner-logo-text {
  font-weight: 500;
  font-size: 42px;
  color: #fff;
}
#breadcrumb {
  --bs-breadcrumb-divider: ">";
  padding-left: 10%;
  padding-bottom: 2%;
}
#breadcrumb .breadcrumb-item a {
  color: #ef586f;
}
#news-card-kangaroo {
  border: none;
  box-shadow: 0px 4px 3px 0px rgba(127, 126, 126, 0.71);
  -webkit-box-shadow: 0px 4px 3px 0px rgba(127, 126, 126, 0.71);
  -moz-box-shadow: 0px 4px 3px 0px rgba(127, 126, 126, 0.71);
}
#news-card-kangaroo:hover {
  transition: 1s;
  position: relative;
  bottom: 5px;
  box-shadow: 0px 5px 8px 2px rgba(127, 126, 126, 0.71);
  -webkit-box-shadow: 0px 5px 8px 2px rgba(127, 126, 126, 0.71);
  -moz-box-shadow: 0px 5px 8px 2px rgba(127, 126, 126, 0.71);
}
#news-card-kangaroo-img img {
  height: 150px !important;
  width: 160px !important;
}

/* // camp  */

.camp-card-container {
  position: relative;
  isolation: isolate;
  height: 280px;
  overflow: hidden;
}
.camp-card-container img {
  width: 100%;
  position: absolute;
  z-index: 1;
  height: 100%;
  inset: 0;
}

.camp-card-content {
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0%;
  z-index: 1;
}
.camp-card-bg {
  position: absolute;
  left: -200%;
  height: 100%;
  width: 100%;
  background-color: #7e52a0;
  opacity: 0.7;
  transition: 0.6s;
  transition-delay: 0.4s;
  z-index: 3;
}
.camp-card-content > section {
  position: absolute;
  height: 100%;
  width: 100%;
  left: -200%;
  z-index: 99;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  transition: 0.5s;
  transition-delay: 0.3s;
  cursor: pointer;
}

.camp-card-container:hover .camp-card-bg {
  left: 0;
  transition: 0.6s;
}

.camp-card-container:hover .camp-card-content > section {
  left: 0;
}

/* top: 1;
z-index: 1;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
background-color: #fff;
width: 80%;
text-align: center;
inset: 0; */
.camp-card-title {
  font-size: 18px;
  font-weight: 600;
  position: relative;
  margin: 10px 20px;
  text-align: center;
}
.camp-card-title::after {
  content: "";
  display: block;
  position: relative;
  margin-top: 10px;
  width: 0%;
  left: 5%;
  background-color: #ccc;
  height: 2px;
  transition: 0.5s;
  transition-delay: 0.8s;
}

.camp-card-container:hover .camp-card-title::after {
  width: 90%;
}
.camp-card-date {
  font-size: 15px;
  font-weight: 600;
}
