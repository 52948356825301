#banner-logo {
  background-image: url(../assets/img/gallery/video/12.jpg);
  padding: 15% 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
#banner-logo-text {
  font-weight: 500;
  font-size: 42px !important;
  color: #fff;
}
#breadcrumb {
  --bs-breadcrumb-divider: ">";
  padding-left: 10%;
  padding-bottom: 4%;
}
#breadcrumb .breadcrumb-item a {
  color: #ef586f;
}
#card-img {
  background-color: #eeeeee;
  font-size: 22px;
}
#card-img img {
  width: 100%;
  height: 280px;
}
@media screen and (max-width: 750px) {
  #banner-logo {
    padding: 160px 0;
  }
  #card-img {
    font-size: 18px;
  }
  #banner-logo-text {
    font-size: 26px !important;
  }
}
@media screen and (max-width: 600px) {
  #banner-logo {
    padding: 130px 0;
  }
}
h1.image-h1-top-text {
  font-size: 35px !important;
  margin: 30px 0px !important;
}
@media (max-width: 576px) {
  h1.image-h1-top-text {
    font-size: 29px !important;
    margin: 30px 0px !important;
  }
}
