.wrapper {
  position: fixed;
  inset: 0;
  z-index: 9999999;
}

.bg {
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;
  background-color: rgb(0, 0, 0);
  opacity: 0.5;
}

.img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  height: 95%;
  width: 95%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.img img {
  height: 100%;
}
.wrapper_icon {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
}

.wrapper_icon i {
  font-size: 30px;
  color: #ffff;
  cursor: pointer;
}

@media (max-width: 650px) {
  .img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    height: 70%;
    width: 70%;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}
