.abouts-us-heading {
  background-image: linear-gradient(
      45deg,
      rgba(161, 161, 161, 0.596),
      rgba(44, 44, 44, 0.493)
    ),
    url("./assets/img/aboutus/aboutus-banner.jpg");
  padding: 13% 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left center;
  color: #fff;
}
#About-us-SectionForP p {
  text-align: justify;
}
/* #owl-me-carousel .col-lg-4 , #owl-me-carousel-board .col-lg-4 {
  width: 90% !important;
 
} */
#owl-me-carousel .card {
  height: 545px;
  /* max-width: 200px; */
}
#owl-me-carousel .card a {
  position: absolute;
  bottom: 15px;
  left: 25%;
  right: 25%;
}

#owl-me-carousel-board .card {
  width: 95%;
  min-height: 400px;
  border: 1px solid #771fbb;
  border-radius: 15px;
  box-shadow: 10px 5px 5px rgb(214, 211, 211);
}
#owl-me-carousel-op .card {
  width: 80%;
  height: auto;
  padding: 10px;
  /* border: 1px solid #771fbb; */
  border: none;
  box-shadow: none;
  border-radius: 15px;
  /* box-shadow: 10px 5px 5px rgb(214, 211, 211); */
}
#owl-me-carousel-board .card img,
#owl-me-carousel-op img {
  border-radius: 50%;

  width: 100%;
  height: auto;

  border: 1px solid #771fbb;
}
#owl-me-carousel img {
  width: 100%;
  height: auto;
}
#owl-me-carousel span,
#owl-me-carousel-board span {
  display: none !important;
}
#readmore-btn {
  background-color: rgb(51, 42, 134);
}
#makeMeSomeHeight p {
  line-height: 2;
  text-align: justify;
}

/* -----------sec2--------- */
#about-sec-1,
#about-sec-2 {
  border: 1px solid rgb(51, 42, 134);
  border-radius: 10px;
  box-shadow: -1px 3px 7px 1px rgb(211, 208, 238);
  -webkit-transition: box-shadow ease-in-out 0.2s;
  transition: box-shadow ease-in-out 0.2s;
}
#about-sec-1:hover,
#about-sec-2:hover {
  box-shadow: none;
}

#about-sec-1 hr,
#about-sec-2 hr {
  color: rgb(51, 42, 134);
  border: 1px solid rgb(51, 42, 134);
}
#ethical {
  height: 140px;
}
#about-us-fertility {
  padding: 0px;
}
#about-us-fertility p {
  text-align: justify;
}
#owl-me-carousel h5 {
  color: #332a86;
}
@media only screen and (max-width: 576px) {
  #owl-me-carousel .card {
    height: auto;
  }
  /* #owl-me-carousel-board .card{
    width: 95%;
  } */
}
