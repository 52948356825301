#doctor-page-card .card {
  border: none;
  position: relative;
  cursor: pointer;
}
#doctor-page-card .card:hover {
  bottom: 5px;
  box-shadow: -1px 4px 10px -3px rgba(66, 66, 66, 0.75);
}
#doctor-page-card img {
  border-radius: 100%;
  box-shadow: -1px 4px 10px -3px rgba(66, 66, 66, 0.75);
  height: 150px;
  width: 150px;
}
#doctor-page-card .havingbtnme {
  border-radius: 25px;
  background-color: #7e52a0;
  color: #fff;
  padding: 10px 20px;
}
#doctor-page-card .havingbtnme:hover {
  background-color: #e26174;
}
.left-side-text {
  background-color: #7e52a0;
}
#each-doctor h3,
#each-doctor h4 {
  color: #7e52a0;
}
#donor-bullet ul {
  list-style-type: none;
}
.font-weight-bold {
  font-weight: bold;
}
#doctor-page-card h5,
h3 {
  color: #771fbb;
}
