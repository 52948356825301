h3.heading {
  border-bottom: 2px solid #fff;
}
footer a {
  text-decoration: none;
  color: #fff;
}
footer a:hover {
  color: rgb(224, 224, 224);
}
.list-unstyled li {
  padding: 2px 0;
}
.list-unstyled a:hover,
.contact-number a:hover {
  color: rgb(224, 224, 224);
}

.list-icons {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  padding: 0 4px;
}
.list-icons ul {
}
.list-icons li {
  padding: 2px 0;
  font-size: 20px;
}
.heading {
  color: #fff;
}
/* footer .fa-envelope-open {
  background-color: blue;
  color: #fff;
  padding: 7px;
  border-radius: 50%;
  font-size: 20px;
} */
@media only screen and (max-width: 300px) {
  .footer-logo {
    width: 250px !important;
  }
}
.important-link h3,
.services-link h3,
.contact-info-link h3 {
  color: #fff;
}

.footer_address h3 {
  border: none;
  margin-bottom: 20px;
}
.footer_address h3::after {
  content: "";
  width: 55%;
  display: block;
  margin-top: 10px;
  border: 2px solid #fff;
}

.footer_address address p {
  margin-bottom: 10px;
}
.footer_address address {
  margin-bottom: 15px;

  width: 90%;
}
.footer_logo_container {
  padding-left: 45px;
}
@media (max-width: 700px) {
  .footer_address h3 {
    margin-top: 30px;
  }
  .first_heading {
    margin-top: 0px !important;
  }
  .footer_logo_container {
    padding-left: 0px;
  }
}
