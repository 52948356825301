.faq button {
  box-shadow: none !important;
  background-color: #eee;
}


.accordion-button:not(.collapsed), .accordion-item:first-of-type .accordion-button, .faq button {
  background-color:#eee !important;
  color: #000 !important;
  
}

