.left_wrapper {
  top: 33%;
  left: 0;
  z-index: 999;
}
.left_callback {
  position: fixed;
  top: 33%;
  left: 0;
  z-index: 999;
}
.left_callback a {
  display: block;
  transform: rotate(-90deg);
  position: relative;
  left: calc(-50% + 20px);
  padding: 10px 15px 12px 15px;
  background-color: #7e52a0;
  color: var(--white) !important;
  font-size: 16px;
  font-weight: 500;
}
.left_request {
  position: fixed;
  bottom: 30%;
  left: 0;
  z-index: 999;
}
.left_request a {
  display: block;
  transform: rotate(-90deg);
  position: relative;
  left: calc(-50% + 20px);
  padding: 10px 15px 12px 15px;
  background-color: #7e52a0;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  color: #fff !important;
}
.left_request a:hover {
  color: #fff !important;
}
.hiddenMobile {
  display: none;
}

@media (max-width: 600px) {
  .left_callback {
    display: none;
  }
  .left_request {
    display: none;
  }
  .left_callback a {
    font-size: 12px;
    padding: 5px 10px 7px 10px;
    left: calc(-50% + 16px);
  }
  .left_request {
    position: fixed;
    bottom: 30%;
    left: 0;
    z-index: 999;
  }
  .left_request a {
    font-size: 12px;
    padding: 5px 10px 7px 10px;
    left: calc(-50% + 16px);
  }
}
