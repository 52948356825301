@media only screen and (max-width: 480px) {
  .blg h1 {
    font-size: 28px !important;
  }
 .blg h5{
    font-size:18px !important;
  }
  .blg h6{
    font-size:16px !important;
  }
  .blg p{
    font-size: 15px;
    
  }
  
}

.blogCard:hover {
  transition: 0.7s;
 box-shadow: 0px 5px 8px 2px rgba(127, 126, 126, 0.71);
 
}
.blogCard p{
   font-size: 14px;
}
.blogCard{
   margin-bottom: 80px;
   position: relative;
}
.blogCard h6{
  position: absolute;
  bottom: 5px;
  right: 10px;
}