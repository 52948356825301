#homeHavingCardTreat .card {
  background-color: #e1e1e2 !important;
  box-shadow: -1px 3px 7px 1px rgba(150, 144, 144, 0.75);
  padding: 15px 0;
  min-height: 390px;
}
#homeHavingCardTreat .card:hover {
  bottom: 2px;
  box-shadow: none;
}
#treatment-page h5{
  color: #813ab8;
}