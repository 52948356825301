.bottomBarContainer {
  position: fixed;
  bottom: 0;
  background-color: #7e52a0;
  z-index: 99;
  width: 100%;
}
.bottomBarWidth {
  width: 90%;
  margin: 0 auto;
  padding: 10px 0px;
  color: #fff;
  font-size: 14px;
}
.bottomBarWidth {
  display: flex;
  gap: 3.3%;
  justify-content: center;
}
.bottomBarWidth > article {
  width: 30%;
  text-align: center;
}
.bottomBarWidth a {
  text-decoration: none;
  padding-left: 10px;
  cursor: pointer;
  color: #fff;
}
.bottomBarWidth a:hover {
  color: #fff;
}
@media (max-width: 600px) {
  .bottomBarWidth {
    width: 98%;
  }
  .bottomBarWidth > article {
    display: flex;
    flex-direction: column;
    font-size: 12px;
  }
  .bottomBarWidth a {
    margin-top: 5px;
  }
}
