.landing-page-container {
  overflow: hidden;
}
#header {
  background-image: url(../assets/img/home/banners/banner-with-welcome1.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}
.form-container {
  background-color: #ffffff;
  margin: 10px;
  padding: 10px;
  /* opacity: 0.5; */
}

@media (min-width: 768px) {
  .form-container {
    background-color: #ffffff;
    padding: 10px;
    margin: 100px;
  }
}

.top-nav-bar img {
  width: 250px;
}
.top-nav-bar .btn-1 {
  background-color: #332a86;
  color: #fff;
}
.top-nav-bar .btn-2 {
  background-color: #ea3288;
  color: #fff;
}
.top-nav-bar .btn-2:hover {
  background-color: #332a86;
  color: #fff;
}
.top-nav-bar .btn-1:hover {
  background-color: #ea3288;
  color: #fff;
}
.btn-talk {
  background-color: #fff !important;
}
.btn-talk:hover {
  background-color: #332a86 !important;
  color: #fff !important;
}

@media (max-width: 576px) {
  .header-form {
    text-align: center;
    width: 80%;
    margin: 0 auto;
  }
  .header-form a:last-child {
    margin-top: 10px;
    display: block;
  }
  .landing-page-container2 {
    text-align: center;
  }
}
/* ----------------
------------
Home---
----------------
------- */

.landing-form-group {
  background-color: #332a86a1;
  padding: 10px;
  border-radius: 20px;
}
.section-top-color {
  background-color: #332a86;
  opacity: 0.5;
  z-index: -1;
  width: fit-content;
}
.myForm-container h1 {
  font-size: 30px !important;
  font-weight: 900 !important;
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 10%;
  /* margin-bottom: 35%; */
  color: white;
  padding: 5px 10px 20px;
}
.myForm-container h2 {
  font-size: 25px !important;
  font-weight: 900 !important;
  font-family: Arial, Helvetica, sans-serif;
  /* margin-bottom: 35%; */
  color: white;
  padding: 5px 10px 20px;
}
@media only screen and (max-width: 767px) {
  .myForm-container h1 {
    font-size: 30px !important;
    font-weight: 900 !important;
  }
  .myForm-container h2 {
    font-size: 25px !important;
    font-weight: 900 !important;
    font-family: Arial, Helvetica, sans-serif;
    /* margin-bottom: 35%; */
    color: white;
  }
}
@media only screen and (max-width: 516px) {
  .myForm-container h1 {
    font-size: 25px !important;
    font-weight: 900 !important;
    margin-top: 30%;
    text-align: center;
  }
  .myForm-container h2 {
    font-size: 22px !important;
    font-weight: 900 !important;
    font-family: Arial, Helvetica, sans-serif;
    /* margin-bottom: 35%; */
    color: white;
    margin-bottom: 20%;
    text-align: center;
  }
}

/* ----------------
------------
about ---
----------------
------- */
.about {
  background-color: #332a86;
}
.about-title h3 {
  color: white;
  font-size: 40px !important;
  text-align: center;
  vertical-align: middle;
  padding-top: 33%;
}
.about-description {
  padding: 40px 62px;
}
.about-description p {
  color: white;
  /* text-align: center; */
  font-size: 19px !important;
  /* padding: 40px 62px; */
}
/* ----------------
------------
services ---
----------------
------- */
.service-title h3 {
  color: #332a86;
  font-size: 40px !important;
  text-align: center;
  vertical-align: middle;
  margin: 3% 0%;
}
.serviceBox p {
  font-size: 14px !important;
}
.serviceBox {
  color: #fff;
  font-family: "Poppins", sans-serif;
  text-align: center;
  padding: 25px 25px 55px;
  position: relative;
  z-index: 1;
  height: 330px;
}
.serviceBox:before,
.serviceBox:after {
  content: "";
  background: linear-gradient(#a64cef, #882fd5);
  border-radius: 20px;
  position: absolute;
  left: 10px;
  right: 0;
  top: 15px;
  bottom: 0;
  z-index: -1;
}
.serviceBox:after {
  background: #fff;
  left: 0;
  right: 10px;
  top: 0;
  bottom: 15px;
  clip-path: polygon(30% 0, 100% 0, 100% 85%, 75% 100%, 0 100%, 0 20%);
}
.serviceBox .service-icon {
  color: #882fd5;
  font-size: 35px;
  text-shadow: 4px 4px rgba(0, 0, 0, 0.1);
  margin: 0 0 10px;
}
.serviceBox .title {
  color: #882fd5;
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
  margin: 0 0 10px;
}
.serviceBox .title span {
  font-weight: 700;
}
.serviceBox .description {
  color: #888;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 0;
}
.serviceBox .read-more {
  color: #fff;
  font-size: 22px;
  line-height: 22px;
  position: absolute;
  right: 15px;
  bottom: 9px;
  transition: all 0.3s ease 0s;
}
.serviceBox .read-more:hover {
  color: rgba(255, 255, 255, 0.7);
  text-shadow: 3px 3px rgba(255, 255, 255, 0.1);
}
.serviceBox.darkgreen:before {
  background: linear-gradient(#00a28d, #007385);
}
.serviceBox.darkgreen .service-icon,
.serviceBox.darkgreen .title {
  color: #007385;
}
.serviceBox.blue:before {
  background: linear-gradient(#00bfe9, #0170dc);
}
.serviceBox.blue .service-icon,
.serviceBox.blue .title {
  color: #0170dc;
}
.serviceBox.green:before {
  background: linear-gradient(#93c916, #3ab447);
}
.serviceBox.green .service-icon,
.serviceBox.green .title {
  color: #3ab447;
}
@media only screen and (max-width: 990px) {
  .serviceBox {
    margin: 0 0 30px;
  }
}
.thank-you {
  text-align: center;
  vertical-align: middle;
  margin-top: 15%;
}

.thank-you p {
  color: black;
  text-align: center;
  font-weight: bold;
  font-size: 2.5em !important;
}

/* choose-us-container */

.choose-us-main-container {
  background-color: #f5f2ee;
  padding: 40px 0px;
}
.choose-us-main-container h2 {
  text-align: center;
  color: #332a86;
  font-size: 40px !important;
  font-weight: 700;
}
.choose-us-container {
  /* border: 2px solid red; */
  width: 85%;
  margin: 50px auto 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.choose-us-container > div {
  width: 25%;
  /* height: 100px; */
  /* border: 2px solid red; */
  margin-top: 20px;
}
.chhose-us-card {
  text-align: center;
  padding: 10px 15px;
}
.chhose-us-card h3 {
  font-size: 18px !important;
  line-height: 25px;
  color: rgb(86, 86, 86);
  font-weight: 700;
  width: 85%;
  margin: 15px auto 0px;
  text-transform: capitalize;
}
.chhose-us-card img {
  height: 100px;
  width: 100px;
}
@media only screen and (max-width: 990px) {
  .choose-us-container > div {
    width: 33.3%;
    /* height: 100px; */
    /* border: 2px solid red; */
    margin-top: 20px;
  }
}
@media only screen and (max-width: 576px) {
  .choose-us-container > div {
    width: 100%;
    /* height: 100px; */
    /* border: 2px solid red; */
    margin-top: 20px;
  }
}

/* ///footer footer-address-container */

.footer-address-container {
  width: 100%;
}
.footer-address-container {
  display: flex;
}
.footer-address-container > div {
  width: 50%;
}
.footer-address-container h3 {
  font-size: 25px !important;
  font-weight: 700;
  margin: 20px 0px !important;
}
.footer-address-container address p {
  margin: 10px 0px 0px;
}
.footer-address-container address div {
  margin-top: 20px !important;
}
@media (max-width: 576px) {
  .footer-address-container {
    flex-direction: column;
  }
  .footer-address-container > div {
    width: 100%;
  }
}

/* doctors cards */
.our-doctors-main-container > div > h3 {
  text-align: center;
  color: #332a86;
  font-size: 40px !important;
  font-weight: 700;
}
.our-doctors-main-container > div > p {
  text-align: center;
  font-size: 20px !important;
  font-weight: 700;
  margin-top: 15px;
}
.our-doctors-container {
  width: 85%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  gap: 1%;
  /* border: 2px solid red; */
  justify-content: center;
}
.our-doctors-container > div {
  width: 24%;
  /* border: 2px solid red; */
  margin-top: 30px;
  margin-bottom: 30px;
}
.our-doctors-card {
  min-height: 560px;
  border: 2px solid #eeee;
  transition: 0.5s;
}
.our-doctors-card:hover {
  border: 4px solid #eeee;

  box-shadow: -1px 1px 10px -5px rgba(0, 0, 0, 0.75);
}
.our-doctors-card img {
  width: 100%;
  margin: 0 auto;
}
.doctors-card-address {
  margin-top: 15px;
  padding: 0px 10px;
}
.doctors-card-address h3 {
  font-size: 22px !important;
  font-weight: 600 !important;
}
.doctor-speciality-title {
  font-weight: bold !important;
  font-size: 17px !important;
}
.doctor-speciality-designation {
  font-size: 16px !important;
  font-weight: 700 !important;
  color: rgb(98, 98, 98);
}
.doc-card-button {
  margin-top: 15px;
}
.doc-card-button a {
  background-color: #332a86 !important;
  padding: 10px;
  border-radius: 10px;
  color: #fff;
  font-weight: bold;
  font-size: 15px;
}
.doc-card-button a:hover {
  color: #fff;
  font-size: 16px;
}

@media (max-width: 576px) {
  .our-doctors-container > div {
    width: 100%;
    /* border: 2px solid red; */
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .our-doctors-card {
    min-height: 560px;
    border: 2px solid #eeee;
    transition: 0.5s;
  }
}

.thank-you-wrapper {
  margin-top: 20px;
}
.thank-you-wrapper a {
  border: 2px solid #7e52a0;
  color: #7e52a0;
  padding: 10px 20px;
  display: block;
  margin-bottom: 20px;
  width: fit-content;
  margin: 0 auto 20px;
  font-weight: 600;

  border-radius: 10px;
}
.thank-you-wrapper p {
  font-size: 30px !important;
}
