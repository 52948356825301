 #mySidenav a {
   position: fixed;
   right: -193px;
   transition:all 0.4s linear;
   padding: 10px 15px;
   width: 240px;
   text-decoration: none;
   font-size: 20px;
   color: #7e52a0;
   border-radius: 5px;
   z-index:999;
  border: 2px groove #7e5280;

 }
 #mySidenav a:hover {
   right: -10px;
   color: #ea3288;
 }
 #call {
   top: 240px;
   background-color: #fff ;
 } 
 #bookapp{
   top: 295px;
   background-color: #fff ;
 }