#banner-logo {
  background-image: url(../assets/img/gallery/video/12.jpg);
  padding: 15% 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
#banner-logo-text {
  font-weight: 500;
  font-size: 42px;
  color: #fff;
}
#breadcrumb {
  --bs-breadcrumb-divider: ">";
  padding-left: 10%;
  padding-bottom: 4%;
}
#breadcrumb .breadcrumb-item a {
  color: #ef586f;
}

h1.videos-h1-top-text {
  font-size: 35px !important;
  margin: 30px 0px !important;
}
@media (max-width: 576px) {
  h1.videos-h1-top-text {
    font-size: 29px !important;
    margin: 30px 0px !important;
  }
}
