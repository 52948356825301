.wrapper {
  background-image: url("../assets/img/aboutus/about-page-section-two.jpg");
  padding: 80px 0;
  background-repeat: no-repeat;
  /* background-size: cover; */
  background-position: center;
 position: relative;
  isolation: isolate;

}
.wrapper::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(126, 82, 160, 0.5);
  z-index: -1;
}
.container h1 {
  text-align: center;
  padding: 30px 0;
  color: white;
}
.items{
    /* background-color:rgba(0, 0, 0, 0.5); */
    padding: 30px;
    color: white;
}
.item{
    /* background-color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    width: 96%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 30px; */
}
.item >div h2{
    border: 2px solid gray;
    border-radius: 50%;
    padding: 10px;
    /* width: 100%;
    margin: 0 auto; */
}
.item >div h2::before{
}